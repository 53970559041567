const environment = {
	api: {
		url: "https://api-preprod.ampmemberships.com",
	},
	localhost: {
		tenantKey: process.env.LOCALHOST_TENANTKEY??"",
	},
	aws: {
		url: "https://preprod-ampmemberships-com.s3.us-west-1.amazonaws.com/public",
	},
	appInsights: {
		instrumentationKey: "a7e4a498-ff57-4fd9-b2c5-49f648c293b2" ,
	},
	sentry: {
		dsn: "https://c02a13f116d645ceaeea9b8fa3f0d5fd@o559438.ingest.sentry.io/5697556",
	},
	mapbox: {
		apiKey: "pk.eyJ1IjoiaW5zaXRlbnhhbXAiLCJhIjoiY2w2NnZpYXB6MDM0bjNjcno2cjJ2a2o5ZyJ9.FhL2-3SmRc25dEQD7Qc4_Q" ,
	},
};

export default environment;
